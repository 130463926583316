import axios from 'axios'
import router from "@/router";
// import { Message } from "element-ui";
// axios.defaults.baseURL = "/v1"

export function request(config) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_INTERFACE_URL,
    timeout: 5000
  })

  instance.interceptors.request.use(
    config => {
      if (localStorage.getItem('Authorization')) {
        config.headers.Authorization = localStorage.getItem('Authorization');
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    });

  instance.interceptors.response.use(res => {
    if (res.data.status === -1) {
      // Message({
      //   showClose: true,
      //   message: res.data.message,
      //   type: "error",
      // });
      localStorage.removeItem('Authorization')
      router.push({ path: 'SignIn' }).then(res => {
        console.log(res)
      })
    } else if (res.data.status === 1) {
      return res
    }
    return res
  }, err => {
    console.log(err);
  })
  return instance(config)
}
